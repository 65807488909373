export type SubMenu = {
  key: string,
  title: string,
  link: string,
  externalURL: string
}

export type HeaderItem = {
  title: string,
  route: string,
  externalURL: string,
  subMenu?: SubMenu[]
}

export const headerItems: HeaderItem[] = [
    {
      title: "Plans",
      route: "/plans",
      externalURL: ""
    },
    {
      "title": "Downloads",
      "route": "",
      "externalURL": "",
      "subMenu": [
        {
          "key": "BB2",
          "title": "Buildbox Classic",
          "link": "/downloads/bb2",
          "externalURL": ""
        },
        {
          "key": "BB3",
          "title": "Buildbox 3",
          "link": "/downloads/bb3",
          "externalURL": ""
        },
        {
          "key": "BB4",
          "title": "Buildbox 4",
          "link": "/downloads/bb4",
          "externalURL": ""
        },                
        {
          "key": "Soundbox",
          "title": "Soundbox",
          "link": "/downloads/soundbox",
          "externalURL": ""
        },
        {
          "key": "Bundle",
          "title": "Bundle",
          "link": "/downloads/bbbundle",
          "externalURL": ""
        }
      ]
    },
    {
      "title": "Products",
      "route": "",
      "externalURL": "",
      "subMenu": [
        {
          "key": "BB2",
          "title": "Buildbox Classic",
          "link": "/product/bb2",
          "externalURL": ""
        },
        {
          "key": "BB3",
          "title": "Buildbox 3",
          "link": "/product/bb3",
          "externalURL": ""
        },
        {"key": "BB4",
          "title": "Buildbox 4",     
          "link": "/product/bb4", 
          "externalURL": "" 
        },    
        {
          "key": "BBWorld",
          "title": "Buildbox World",
          "link": "/product/buildboxworld",
          "externalURL": "https://buildboxworld.com/" 
        },      
        {
          "key": "Soundbox",
          "title": "Soundbox",
          "link": "/product/soundbox",
          "externalURL": ""
        },
        {
          "key": "StoryGames",
          "title": "Story Games",
          "link": "/product/storygames",
          "externalURL": ""
        }
      ]
    },
    // {
    //   "title": "Learning Center",
    //   "route": "",
    //   "externalURL": "",
    //   "subMenu": [
    //     {
    //       "key": "BB2Tutorials",
    //       "title": "Buildbox Classic",
    //       "link": "",
    //       "externalURL": "https://www.buildbox.com/tutorials-buildbox-classic"
    //     },
    //     {
    //       "key": "BB3Tutorials",
    //       "title": "Buildbox 3",
    //       "link": "",
    //       "externalURL": "https://www.buildbox.com/tutorials-buildbox-3"
    //     }     
    //   ]
    // },
    {
      "title": "Community",
      "route": "",
      "externalURL": "",
      "subMenu": [
        {
          "key": "productfeedback",
          "title": "Product Feedback",
          "link": "",
          "externalURL": "http://productboard.buildbox.com"
        },
        {
          "key": "gamejam",
          "title": "Game Jam",
          "link": "",
          "externalURL": "https://www.buildbox.com/gamejam"
        },
        {
          "key": "blog",
          "title": "Buildbox Blog",
          "link": "",
          "externalURL": "https://www.buildbox.com/blog/"
        },
        {
          "key": "forum",
          "title": "Buildbox Forum",
          "link": "",
          "externalURL": "https://www.buildbox.com/forum/index.php"
        },
        {
          "key": "discord",
          "title": "Buildbox Discord",
          "link": "",
          "externalURL": "https://discord.com/invite/buildbox"
        }
      ]
    },
    // {
    //   "title": "Pricing",
    //   "route": "/plans",
    //   "externalURL": ""
    // },
    {
      "title": "Help",
      "route": "",
      "externalURL": "",
      "subMenu": [
        {
          "key": "reportissue",
          "title": "Report an Issue",
          "link": "/reportAnIssue",
          "externalURL": ""
        },
        {
          "key": "contact",
          "title": "Contact Support",
          "link": "/contactSupport",
          "externalURL": "" 
        },
        {
          "key": "manual",
          "title": "Buildbox Manual",
          "link": "",
          "externalURL": "https://www.buildbox.com/help/buildbox-3-manual"
        },
        {
          "key": "api",
          "title": "API Reference",
          "link": "",
          "externalURL": "https://www.buildbox.com/help/buildbox-3-api-reference/"
        },
        {
          "key": "howto",
          "title": "How To/FAQs",
          "link": "",
          "externalURL": "https://www.buildbox.com/help/how-to-faqs/"
        }
      ]
    }
  ]

export const profileHeaderItems: HeaderItem[] = [
      {
        "title": "Profile",
        "route": "",
        "externalURL": "",
        "subMenu": [
          {
            "key": "profileInformation",
            "title": "Profile Information",
            "link": "/account/profile",
            "externalURL": ""
          },
          {
            "key": "subscription",
            "title": "Subscription",
            "link": "/account/subscription",
            "externalURL": ""
          },
          {
            "key": "monetization",
            "title": "Adbox",
            "link": "/account/ads/earnings",
            "externalURL": ""
          },
          {
            "key": "support",
            "title": "Profile Support",
            "link": "/account/support",
            "externalURL": ""
          }
        ]
      }
    ]
