import { PlanType, PlanIds, ProductType, ProductCategory, PlanInterval } from "../reducers/models";
import { analyticProductTierNames } from "../shared/utility";

const PLANS_JSON: bb.model.PlanJSON = {
  BB4Free: {
    id: PlanIds.BB4Free,
    productType: ProductType.BB4,
    planType: PlanType.Free,
    planInterval: PlanInterval.Lifetime,
    productCategory: ProductCategory.BB4,
    planName: "Free",
    analyticsTierName: analyticProductTierNames.Free,
    discountsAllowed: true,
    trialDays: 0,
    monthlyCost: 0,
    annualCost: 0,
    lifetimeCost: 0,
    yearCommitmentCost: 0,
    features: [
      "2D+3D Games",
      "1 World",
      "20 Scenes",
      "Buildbox Shop Access",
    ],
    downloadUrl: "/downloads/bb4",
  },
  // AP: if this is brought back it should probably be a 'Beginner' plan not 'Weekly'
  // [PlanIds.BB4Weekly]: {
  //   id: PlanIds.BB4Weekly,
  //   productType: ProductType.BB4,
  //   planType: PlanType.Weekly,
  //   planInterval: PlanInterval.Weekly,
  //   productCategory: ProductCategory.BB4,
  //   planName: "Beginner",
  //   analyticsTierName: analyticProductTierNames.Weekly, // should probably be/become 'Beginner'
  //   stripeId: {
  //     Weekly: "BB4Weekly3_99",
  //   },   
  //   stripeIdDev: {
  //     Weekly: "BB4Weekly3_99",
  //   },
  //   xsollaProductId: 11565942,
  //   xsollaId: {
  //     Weekly: "6ksFTWCU",
  //   },
  //   xsollaProductIdDev: 11561953,
  //   xsollaIdDev: {
  //     Weekly: "LaxgtzZv",
  //   },
  //   trialDays: 5,
  //   weeklyCost: 3.99,    
  //   features: [
  //     "Mac and Windows Client",
  //     "3D Games",
  //     "Unlimited Worlds & Scenes",
  //     "Unlimited Preview Usage",
  //     "50 AI Tokens/week",
  //     "AI Assistant",
  //     "Preview Only",
  //     "Customizable Buildbox Splash Screen",
  //     "You keep 100% of the Sales Revenue"
  //   ],
  //   downloadUrl: "/downloads/bb4",
  // },
  BB4Basic: {
    id: PlanIds.BB4Basic,
    productType: ProductType.BB4,
    planType: PlanType.Basic,
    planInterval: PlanInterval.Yearly,
    productCategory: ProductCategory.BB4,
    planName: "Basic",
    analyticsTierName: analyticProductTierNames.Plus,
    discountsAllowed: true,
    stripeId: {
      Yearly: "BB4Basic180",
    },   
    stripeIdDev: {
      Yearly: "BB4Basic180",
    },
    xsollaProductId: 11565942,
    xsollaId: {
      Yearly: "BtH7NNso",
    },
    xsollaProductIdDev: 11561953,
    xsollaIdDev: {
      Yearly: "J5RclsiM",
    },    
    trialDays: 0,
    annualCost: 180.00,
    features: [
      "Mac and Windows Client",
      "3D Games",
      "5 Worlds",
      "50 Scenes",
      "Exports to Android and PC",
      "Unlimited Android and PC Exports",
      "200 AI Tokens/month",
      "AI Assistant",
      "Customizable Buildbox Splash Screen",
      "You keep 100% of the Sales Revenue"
    ],
  },
  BB4Pro: {
    id: PlanIds.BB4Pro,
    productType: ProductType.BB4,
    planType: PlanType.Pro,
    planInterval: PlanInterval.Yearly,
    productCategory: ProductCategory.BB4,
    planName: "Pro",
    analyticsTierName: analyticProductTierNames.Pro,
    discountsAllowed: true,
    stripeId: {
      Yearly: "BB4ProYearly300",
    },
    stripeIdDev: {
      Yearly: "BB4ProYearly300",
    },
    xsollaProductId: 11565942,
    xsollaId: {
      Yearly: "JzYYOeRL",
    },
    xsollaProductIdDev: 11561953,
    xsollaIdDev: {
      Yearly: "FHyLKpdq",
    },     
    trialDays: 0,
    annualCost: 300.00,
    //badgeText: "MOST POPULAR",
    featured: false,
    features: [
      "Mac and Windows Client",
      "3D Games",
      "Unlimited Worlds",
      "Unlimited Scenes",
      "Exports to Android and PC",
      "Unlimited Android and PC Exports",
      "350 AI Tokens/month",
      "AI Assistant, AI Code Edit, & Advance Placement Tools",
      "Customizable Buildbox Splash Screen",
      "You keep 100% of the Sales Revenue",
      "Controller Support",
    ],
  },
  BB4TrialUpsell: {
    id: PlanIds.BB4TrialUpsell,
    productType: ProductType.BB4,
    planInterval: PlanInterval.Yearly,
    planType: PlanType.Pro,
    productCategory: ProductCategory.BB4,
    planName: "Pro",
    analyticsTierName: analyticProductTierNames.Pro,
    discountsAllowed: false,
    stripeId: {
      Yearly: "BB4TrialUpsellProductAug2024",
    },
    stripeIdDev: {
      Yearly: "BB4TrialUpsellProductAug2024",
    },
    xsollaProductId: 11565942,
    xsollaId: {
      Yearly: "NnO7M1HX",
    },
    xsollaProductIdDev: 11561953,
    xsollaIdDev: {
      Yearly: "RGAhE7P3",
    },     
    trialDays: 0,
    annualCost: 150.00,
    features: [
      "Windows Client",
      "3D Games",
      "Unlimited Worlds & Scenes",
      "Unlimited PC Exports",
      "350 AI Tokens/month",
      "AI Assistant, AI Code Edit, & Advance Placement Tools",
      "Exports to PC",
      "Customizable Buildbox Splash Screen",
      "You keep 100% of the Sales Revenue"
    ],
  },
  [PlanIds.BB4Beginner]: {
    id: PlanIds.BB4Beginner,
    productType: ProductType.BB4,
    planType: PlanType.Beginner,
    planInterval: PlanInterval.Monthly,
    productCategory: ProductCategory.BB4,
    planName: "Beginner",
    analyticsTierName: analyticProductTierNames.Beginner,
    discountsAllowed: true,
    stripeId: {
      Monthly: "BB4BeginnerMonthlyAug2024_1999corrected",
    },   
    stripeIdDev: {
      Monthly: "BB4BeginnerMonthlyAug2024_1999corrected",
    },
    xsollaProductId: 11565942,
    xsollaId: {
      Monthly: "ntySHdcq",
    },
    xsollaProductIdDev: 11561953,
    xsollaIdDev: {
      Monthly: "R2zKGSb2",
    },
    trialDays: 5,
    monthlyCost: 19.99,
    features: [
      "Mac and Windows Client",
      "3D Games",
      "1 World",
      "20 Scenes",
      "Preview Only",
      "Unlimited Preview Usage",
      "200 AI Tokens/week",
      "AI Assistant",
      "Customizable Buildbox Splash Screen",
      "You keep 100% of the Sales Revenue"
    ],
    downloadUrl: "/downloads/bb4",
  },  
  



  SoundboxFree: {
    id: PlanIds.SoundboxFree,
    productType: ProductType.Soundbox,
    planType: PlanType.Free,
    planInterval: PlanInterval.Lifetime,
    productCategory: ProductCategory.Soundbox,
    planName: "Free",
    analyticsTierName: analyticProductTierNames.Free,
    discountsAllowed: true,
    trialDays: 0,
    monthlyCost: 0,
    annualCost: 0,
    lifetimeCost: 0,    
    yearCommitmentCost: 0,
    features: [
      ""
    ],
    downloadUrl: "/downloads/soundbox",
  },
  SoundboxPro: {
    id: PlanIds.SoundboxPro,
    productType: ProductType.Soundbox,
    planType: PlanType.Pro,
    planInterval: PlanInterval.Yearly,
    productCategory: ProductCategory.Soundbox,
    planName: "Pro",
    analyticsTierName: analyticProductTierNames.Pro,
    discountsAllowed: true,
    trialDays: 0,
    annualCost: 49.99,
    stripeId: {
      Yearly: "SoundboxProYearly49_Jul2023",
    },
    stripeIdDev: {
      Yearly: "SoundboxProYearly49_Jul2023",
    },
    xsollaProductId: 9556929,
    xsollaId: {
      Yearly: "RjSXQSkH",
    },
    xsollaProductIdDev: 9556922,
    xsollaIdDev: {
      Yearly: "M1ty9K4o",
    },
    //badgeText: "MOST POPULAR",
    featured: false,
    features: [
      "Unlimited Access",
      "Unlimited Editing",
`Royalty free access to library of over 7,500 Audio Clips 
(Valued at over $10,000!)`,
      "Both Music & SFX files included",
      "Import & Edit your own Audio Files",
      "Export your files to .aif, .flac, .mp3, or .wav"
    ],
    downloadUrl: "/downloads/soundbox",
  },  


  BB3Free: {
    id: PlanIds.BB3Free,
    productType: ProductType.BB3,
    planType: PlanType.Free,
    planInterval: PlanInterval.Lifetime,
    productCategory: ProductCategory.BB3,
    planName: "Free",
    analyticsTierName: analyticProductTierNames.Free,
    discountsAllowed: true,
    trialDays: 0,
    monthlyCost: 0,
    annualCost: 0,
    lifetimeCost: 0,    
    yearCommitmentCost: 0,
    features: [
      "2D+3D Games",
      "1 World",
      "20 Scenes",
      "Buildbox Shop Access",
      "Easy Game Wizard",
      "5 Exports to Buildbox World",
      "2 Story Game Creations in Buildbox World",
    ],
    downloadUrl: "/downloads/bb3",
  },
  [PlanIds.BB3Monthly]: {
    id: PlanIds.BB3Monthly,
    productType: ProductType.BB3,
    planType: PlanType.Monthly,
    planInterval: PlanInterval.Monthly,
    productCategory: ProductCategory.Monthly,
    planName: "Monthly",
    analyticsTierName: analyticProductTierNames.Monthly,
    discountsAllowed: false,
    trialDays: 0,
    monthlyCost: 19.99,
    stripeIdDev: {
      Monthly: "BB3Monthly1999_Feb2023_product"
    },
    stripeId: {
      Monthly: "BB3Monthly1999_Feb2023_product"
    },
    xsollaProductIdDev: 6861430,
    xsollaIdDev: {
      Monthly: "v0y3BAIw"
    },
    xsollaProductId: 8667190,
    xsollaId: {
      Monthly: "B4Ayhwyd"
    },
    features: [
      "Unlimited Worlds",
      "Unlimited Scenes",
      "Customizable Splash Screen",
      "Unlimited exports to Buildbox World",
       "100 Story Game Creations in Buildbox World"
    ],
    nonGridFeatures: [
      "People can play your games inside the Buildbox World App available on both the Apple App Store and Google Play"
    ],
    downloadUrl: "/downloads/bb3",
    featured: false,
    badgeText: "Buildbox 3"
  },
  BB3Plus: {
    id: PlanIds.BB3Plus,
    productType: ProductType.BB3,
    planType: PlanType.Plus,
    planInterval: PlanInterval.Yearly,
    productCategory: ProductCategory.BB3,
    planName: "Plus",
    analyticsTierName: analyticProductTierNames.Plus,
    discountsAllowed: true,
    stripeId: {
      Yearly: "BB3PlusYearly97_Jul2023",
    },   
    stripeIdDev: {
      Yearly: "BB3PlusYearly97_Jul2023",
    },
    xsollaProductId: 8667190,
    xsollaId: {
      Yearly: "3XUFoDk2",
    },
    xsollaProductIdDev: 6861430,
    xsollaIdDev: {
      Yearly: "0LDcGy99",
    },    
    trialDays: 0,
    annualCost: 97.99,
    features: [
      "2D+3D Games",
      "5 Worlds",
      "50 Scenes",
      "Buildbox Shop Access",
      "Easy Game Wizard",
      "Unlimited Buildbox World Exports",
      "Export to iOS, Android, and PC",
      "Customizable Splash Screen",
      "You keep 100% of all Apple App Store Revenue",
      "You keep 100% of all Google Play Store Revenue",
      "You keep 100% of all App Store Revenue",
      "You keep 70% of all Adbox Revenue",
       "100 Story Game Creations in Buildbox World"
    ],
  },
  BB3Pro: {
    id: PlanIds.BB3Pro,
    productType: ProductType.BB3,
    planType: PlanType.Pro,
    planInterval: PlanInterval.Yearly,
    productCategory: ProductCategory.BB3,
    planName: "Pro",
    analyticsTierName: analyticProductTierNames.Pro,
    discountsAllowed: true,
    stripeId: {
      Yearly: "BB3ProYearly297_Jul2023",
    },
    stripeIdDev: {
      Yearly: "BB3ProYearly297_Jul2023",
    },
    xsollaProductId: 8667190,
    xsollaId: {
      Yearly: "fjXnL4cZ",
    },
    xsollaProductIdDev: 6861430,
    xsollaIdDev: {
      Yearly: "a1kQBZzz",
    },     
    trialDays: 0,
    annualCost: 297.99,
    //badgeText: "MOST POPULAR",
    featured: false,
    features: [
      "2D+3D Games",
      "Unlimited Worlds",
      "Unlimited Scenes",
      "Buildbox Shop Access",
      "Easy Game Wizard",
      "Unlimited Buildbox World Exports",
      "Export to iOS, Android, and PC",
      "Customizable Splash Screen",
      "You keep 100% of all Apple App Store Revenue",
      "You keep 100% of all Google Play Store Revenue",
      "You keep 100% of all App Store Revenue",
      "You keep 90% of all Adbox Revenue",
      "You keep 100% of all Custom Ad Network Revenue",
      "100 Story Game Creations in Buildbox World"
    ]
  },


  BB2Free: {
    id: PlanIds.BB2Free,
    productType: ProductType.BB2,
    planType: PlanType.Free,
    planInterval: PlanInterval.Lifetime,
    productCategory: ProductCategory.BB2,
    planName: "Free",
    analyticsTierName: analyticProductTierNames.Free,
    discountsAllowed: true,
    trialDays: 0,
    annualCost: 0,
    lifetimeCost: 0,    
    features: [
      "2D Games",
      "1 World",
      "20 Scenes",
      "5 Exports to Buildbox World",
      "2 Story Game Creations"
    ],
    downloadUrl: "/downloads/bb2",
  },
  [PlanIds.BB2Monthly]: {
    id: PlanIds.BB2Monthly,
    productType: ProductType.BB2,
    planType: PlanType.Monthly,
    planInterval: PlanInterval.Monthly,
    productCategory: ProductCategory.Monthly,
    planName: "Monthly",
    analyticsTierName: analyticProductTierNames.Monthly,
    discountsAllowed: false,
    trialDays: 0,
    monthlyCost: 9.99,
    stripeIdDev: {
      Monthly: "BBClassicMonthly999_Feb2023"
    },
    stripeId: {
      Monthly: "BBClassicMonthly999_Feb2023"
    },
    xsollaProductIdDev: 8629752,
    xsollaIdDev: {
      Monthly: "fVyLnLbJ"
    },
    xsollaProductId: 8667189,
    xsollaId: {
      Monthly: "Xu1FUltl"
    },
    features: [
      "AI Access",
      "Unlimited Worlds",
      "Unlimited Scenes",
      "Customizable Splash Screen",
      "Unlimited exports to Buildbox World",
       "100 Story Game Creations"
    ],
    nonGridFeatures: [
      "People can play your games inside the Buildbox World App available on both the Apple App Store and Google Play"
    ],
    downloadUrl: "/downloads/bb2",
    featured: false,
    badgeText: "Buildbox Classic"
  },
  BB2Plus: {
    id: PlanIds.BB2Plus,
    productType: ProductType.BB2,
    planType: PlanType.Plus,
    planInterval: PlanInterval.Yearly,
    productCategory: ProductCategory.BB2,
    planName: "Plus",
    analyticsTierName: analyticProductTierNames.Plus,
    discountsAllowed: true,
    stripeId: {
      Yearly: "BB2PlusYearly57_Jul2023",
    },  
    stripeIdDev: {
      Yearly: "BB2PlusYearly57_Jul2023",
    },
    xsollaProductId: 8667189,
    xsollaId: {
      Yearly: "ZEgygGn0",
    },
    xsollaProductIdDev: 8629752,
    xsollaIdDev: {
      Yearly: "6d9v8BiM",
    },     
    trialDays: 0,
    annualCost: 57.99,
    features: [
      "2D Games",
      "5 Worlds",
      "50 Scenes",
      "Unlimited Buildbox World Exports",
      "100 Story Game Creations",
      "AI Access",
      "Export to iOS, Android, and PC",
      "Customizable Splash Screen",
      "You keep 100% of all Apple App Store Revenue",
      "You keep 100% of all Google Play Store Revenue",
      "You keep 100% of all In-App Purchases",
      "You keep 70% of all Adbox Revenue",
    ],
  },
  BB2Pro: {
    id: PlanIds.BB2Pro,
    productType: ProductType.BB2,
    planType: PlanType.Pro,
    planInterval: PlanInterval.Yearly,
    productCategory: ProductCategory.BB2,
    planName: "Pro",
    analyticsTierName: analyticProductTierNames.Pro,
    discountsAllowed: true,
    stripeId: {
      Yearly: "BB2ProYearly137_Jul2023",
    },
    stripeIdDev: {
      Yearly: "BB2ProYearly137_Jul2023",
    },
    xsollaProductId: 8667189,
    xsollaId: {
      Yearly: "EdHOCc0Z",
    },
    xsollaProductIdDev: 8629752,
    xsollaIdDev: {
      Yearly: "IxGUjWSp",
    },   
    trialDays: 0,
    annualCost: 137.99,
    //badgeText: "MOST POPULAR",
    featured: false,
    features: [
      "2D Games",
      "Unlimited Worlds",
      "Unlimited Scenes",
      "Unlimited Buildbox World Exports",
      "100 Story Game Creations",
      "AI Access",
      "Export to iOS, Android, and PC",
      "Customizable Splash Screen",
      "You keep 100% of all Apple App Store Revenue",
      "You keep 100% of all Google Play Store Revenue",
      "You keep 100% of all In-App Purchases",
      "You keep 90% of all Adbox Revenue",
      "You keep 100% of all Custom Ad Network Revenue",
    ],
  },
  [PlanIds.BB2Beginner]: {
    id: PlanIds.BB2Beginner,
    productType: ProductType.BB2,
    planType: PlanType.Beginner,
    planInterval: PlanInterval.Monthly,
    productCategory: ProductCategory.BB2,
    planName: "Beginner",
    analyticsTierName: analyticProductTierNames.Beginner,
    discountsAllowed: true,
    stripeId: {
      Monthly: "BB2BeginnerMonthly999_Aug2024",
    },
    stripeIdDev: {
      Monthly: "BB2BeginnerMonthly999_Aug2024",
    },
    xsollaProductId: 8667189,
    xsollaId: {
      Monthly: "Stp68TgM",
    },
    xsollaProductIdDev: 8629752,
    xsollaIdDev: {
      Monthly: "8L3jTrMn",
    },       
    trialDays: 5,
    monthlyCost: 9.99,
    features: [
      "2D Games",
      "1 World",
      "20 Scenes",
      "5 Exports to Buildbox World",
      "2 Story Game Creations"
    ],
    downloadUrl: "/downloads/bb2",
  },
  BB2TrialUpsell: {
    id: PlanIds.BB2TrialUpsell,
    productType: ProductType.BB2,
    planType: PlanType.Pro,
    planInterval: PlanInterval.Yearly,
    productCategory: ProductCategory.BB2,
    planName: "Pro",
    analyticsTierName: analyticProductTierNames.Pro,
    discountsAllowed: false,
    stripeId: {
      Yearly: "BB2TrialUpsellProductAug2024",
    },
    stripeIdDev: {
      Yearly: "BB2TrialUpsellProductAug2024",
    },
    xsollaProductId: 8667189,
    xsollaId: {
      Yearly: "loW1sxmW",
    },
    xsollaProductIdDev: 8629752,
    xsollaIdDev: {
      Yearly: "07zdqsA1",
    },     
    trialDays: 0,
    annualCost: 69.99,
    features: [
      "2D Games",
      "Unlimited Worlds",
      "Unlimited Scenes",
      "Unlimited Buildbox World Exports",
      "AI Access",
      "Export to iOS, Android, and PC",
      "Customizable Splash Screen",
      "You keep 100% of all Apple App Store Revenue",
      "You keep 100% of all Google Play Store Revenue",
      "You keep 100% of all In-App Purchases",
      "You keep 90% of all Adbox Revenue",
      "You keep 100% of all Custom Ad Network Revenue",
      "100 Story Game Creations"
    ],
  },  
  
  
  BBBundle: {
    id: PlanIds.BBBundle,
    planType: PlanType.Bundle,
    planInterval: PlanInterval.Yearly,
    planName: "Ultimate Bundle",
    analyticsTierName: analyticProductTierNames.Bundle,
    discountsAllowed: true,
    productType: ProductType.BBBundle,
    productCategory: ProductCategory.BBBundle,
    xsollaProductId: 9247097,
    xsollaId: {
      Yearly: "sTgpWNWl"
    }, 
    xsollaProductIdDev: 9247069,
    xsollaIdDev: {
      Yearly: "ns4jAJh2"
    },
    stripeId: {
      Yearly: "UltimateBundleYearly"
    },
    stripeIdDev: {
      Yearly: "UltimateBundleYearly"
    },
    trialDays: 0,
    annualCost: 599.99,
    preBundleCost: 785.97,
    //badgeText: "BEST VALUE",
    featured: false,
    features: [
      "Buildbox Classic Pro",
      "Buildbox 3 Pro",
      "Buildbox 4 Pro",
      "Soundbox",
    ]
  }
};

export default PLANS_JSON;

export const PLAN_MAP: Record<ProductCategory,bb.model.ISubscription[]> = {
  // the order the plans are in these arrays is the order that they'll appear on the pricing/plans page
  Monthly: [
    PLANS_JSON.BB2Monthly,
    // PLANS_JSON.BB3Monthly,
    // PLANS_JSON.BB4Monthly,
    PLANS_JSON.BBBundle,
  ],
  BB4: [
    // PLANS_JSON.BB4Free,
    //PLANS_JSON.BB4Weekly,
    PLANS_JSON.BB4Beginner,
    PLANS_JSON.BB4Basic,
    PLANS_JSON.BB4Pro,
    PLANS_JSON.BBBundle,
  ],  
  BB3: [
    PLANS_JSON.BB3Free,
    PLANS_JSON.BB3Plus,
    PLANS_JSON.BB3Pro,
    PLANS_JSON.BBBundle,
  ],
  BB2: [
    //PLANS_JSON.BB2Free,
    PLANS_JSON.BB2Beginner,
    PLANS_JSON.BB2Plus,
    PLANS_JSON.BB2Pro,
    PLANS_JSON.BBBundle,
  ],
  Soundbox: [
    PLANS_JSON.SoundboxPro,
    PLANS_JSON.BBBundle,
  ],
  BBBundle: [
    PLANS_JSON.BBBundle
  ]
}
