import Log from "../utils/Log";
import { isTablet, isMobileOnly } from "react-device-detect";
import { ProductType } from "../reducers/models";

/**
 * Note about the setTimeout - calling federatedSignIn results in 2 calls to the redirect causing the app to lost context of the correct route.
 * Fix when issue is resolved here: https://github.com/aws-amplify/amplify-js/issues/4822
 */
export const redirectToDownloads = (props, version) => {
    props.history.push(`/downloads/${version.toLowerCase()}`);
};

// TODO: make an enum for the products? I tried but it basically said it was typescript only
/**
 *
 * @param {*} props
 * @param {*} productName
 * @param {string|undefined|null} promoCode
 */
export const redirectToPreferredPaymentPlatformForProduct = (
  props,
  productName,
  referralInfo = null,
  promoCode = null
) => {
  if (productName === ProductType.BB2 || productName === ProductType.BB3 || 
    productName === ProductType.BBBundle || productName === ProductType.Soundbox || productName === ProductType.BB4) {
    if (referralInfo?.Network?.toLowerCase() === "xsolla") {
      redirectToXsollaCheckout(props, promoCode);
    } else {
      redirectToStripeCheckout(props, promoCode);
    }
  } else {
    // AP: now what? throw error? redirect to 404?
    console.error("unexpected product name: ", productName);
    props.history.push("/plans");
    // Why throw error?
    // throw(new Error("Unexpected product name: ", productName))
  }
};

export const redirectToStripeCheckout = (props, promoCode) => {
  if (promoCode) {
    props.history.push(`/purchaseThroughStripe?promo=${promoCode}`);
  } else {
    props.history.push("/purchaseThroughStripe");
  }
};

export const redirectToXsollaCheckout = (props, promoCode) => {
  if (promoCode) {
    props.history.push(`/purchaseThroughXsolla?promo=${promoCode}`);
  } else {
    props.history.push("/purchaseThroughXsolla");
  }
};


export const redirectToPlans = (props, url, loc, promoCode) => {
  if (url) {
    props.history.push(`/plans/${url.toLowerCase()}`);
  } else if (promoCode) {
    props.history.push(`/plans?promo=${promoCode}`);
  } else {
    props.history.push("/plans");
  }
  Log.trace(loc, "redirecting to plans");
};

export const redirectToHome = (props) => {
  Log.trace("user not logged in - redirecting back to home");

  props.history.push("/");
  // window.location.href = "http://buildbox.com";
};

export const redirectToAuthUI = (props, error) => {
  Log.trace("error logging in - redirecting to error page");

  const errorURLString = error.message.toLowerCase();
  let errorQuery = "";
  if (errorURLString.includes("presignup")) {
    errorQuery = "presignup-error";
  }
  Log.error("heard sign in error", "redirectToAuthUI - routes.js");

  props.history.replace(`/error?${errorQuery}`);
};

export const redirectToThankYouPage = (props) => {
  props.history.push("/thankyou");
};

export const redirectToThankYouRenewalPage = (props) => {
  props.history.push("/renewalthankyou")
}

export const redirectToPreviousPage = (props) => {
  const route = sessionStorage.getItem("previousRoute");

  Log.trace(route, "redirecting to previousRoute:");

  if (route !== null) {
    props.history.push(route);
  } else {
    props.history.push("/");
  }
};

export const redirectToLoginPage = (props) => {
  props.history.push("/login");
};

export const redirectToDeviceDownloadPage = (props, version, currentUser) => {
  Log.trace(version, "SETTING VERSION: ");

  sessionStorage.setItem("productType", version);
  sessionStorage.setItem("websocket-product", version);

  if (isMobileOnly || isTablet) {
    if (currentUser) {
      redirectToThankYouPage(props);
    } else {
      redirectToLoginPage(props);
    }
  } else {
    redirectToDownloads(props, version);
  }
};

export const redirectToDeviceDownloadPageSimple = (props, version) => {
  if (isMobileOnly || isTablet) {
    redirectToThankYouPage(props);
  } 
  else {
    redirectToDownloads(props, version);
  }
};

export const redirectToTrialUpsellPage = (props, productType, paymentPlatform) => {
  props.history.push({
    pathname: '/trialSignupComplete',
    state: { productType, paymentPlatform }
  });
};
